.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.header-container {
	position: fixed;
	top: 0px;
	left: 0px;
	background-color: white;
	z-index: 998;
	.offer-carousel {
		display: flex;
		justify-content: space-between;
		.offer-name {
			font-size: 12px;
			padding-top: 5px;
		}
	}
}

.nav-row {
	padding: 5px;
	border-bottom: 1px solid #e9e9e9;
	.nav-bar {
		justify-content: flex-end;
		ul {
			font-size: 12px;
			line-height: 16px;
			list-style-type: none;
			margin: 0;
			padding: 0;
			color: #6e6e6e;
			li {
				display: inline;
				a {
					display: inline-block;
					margin-left: 32px;
				}
			}
		}
	}
}
.top-carousel {
	text-align: center;
}
.search-nav-row {
	.aistetic-logo {
		text-align: center;
	}

	.nav-bar {
		padding: 5px;
		justify-content: flex-end;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			color: #000;
			li {
				display: inline;
				a {
					font-weight: 700;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 0.02em;
					display: inline-block;
					margin-left: 32px;
				}
			}
		}
	}
	.search-section {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-end;
		i {
			margin-right: 30px;
		}
		.search-input-group {
			flex-wrap: nowrap;
			i {
				text-align: center;
				margin: 0;
				border-bottom: 1px solid #e9e9e9;
			}
			input {
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 1px solid #e9e9e9;
				width: 208px;
			}
		}
	}
}

//TYPES ROW

.types-row {
	display: flex;
	flex-direction: row;
	margin-left: 4vw;
	overflow-x: scroll;
	.type-container {
		display: flex;
		flex-direction: column;
		// padding:0 10px;
		// width: 16vw;
		margin-right: 2.28vw;
		.type-title {
			color: #000;
			line-height: 24px;
			font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
			font-weight: 700;
			margin-top: 20px;
			text-align: left;
		}
	}
}

.pl-section {
	margin-top: 100px;
}

.pl-top-container {
	text-align: center;
	font-size: 12px;
	line-height: 16px;
	font-family: 'Helvetica-Now-Text-Bold', Helvetica, Arial, sans-serif;
	padding: 20px;
	.breadcrumb_main {
		color: #6e6e6e;
	}
	.breadcrumb_active {
		color: #000000;
		font-weight: bold;
	}
	h1 {
		color: #000000;
		text-transform: uppercase;
		font-family: 'InterstateWGL-Black', Helvetica, Arial, sans-serif;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.02em;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}

.pl-main-container {
	margin: 20px;
	.product-list-section {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.product-cell {
			padding-right: 4%;
			padding-bottom: 64px;
			.product-img {
				margin-bottom: 24px;
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			.product-details {
				color: #000000;
				font-size: 14px;
				line-height: 20px;
				font-family: 'Helvetica-Now-Text-Regular', Helvetica, Arial, sans-serif;
				margin-bottom: 4px;
				// .product-name {
				// }
				.product-price {
					font-family: 'Helvetica-Now-Text-Bold', Helvetica, Arial, sans-serif;
					font-weight: bold;
				}
			}
		}
	}
}

.offer-carousel-section {
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
}

.filter-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	.filter-head {
		color: #000;
		font-size: 12px;
		width: auto;
		&.filter {
			color: #000;
			font-weight: 700;
		}
		&.item-count {
			line-height: 16px;
			font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
			color: #6e6e6e;
		}
	}
}

.mobile-menu {
	width: 88vw;
	position: fixed;
	background-color: #fff;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 999;
	&.modal-dialog {
		margin: 0px;
		.modal-header {
			margin-left: 28px;
			border-bottom: 0px;
			.modal-title {
				img {
					height: 24px;
				}
			}
		}
		.modal-body {
			ul {
				list-style-type: none;
				padding-top: 32px;
				padding-bottom: 56px;
				li {
					color: #000;
					text-transform: uppercase;
					font-family: Helvetica, Arial, sans-serif;
					font-weight: 700;
					font-size: 24px;
					line-height: 24px;
					letter-spacing: 0.02em;
					padding-bottom: 24px;
					padding-top: 0;
				}
			}
		}
	}
}

.pd-section {
	.gallery-section {
		display: flex;
		// flex-direction: row;
		.thumbnail-list {
			padding-right: 20px;
			display: flex;
			// flex-direction: column;
			.thumbnail {
				border: 1px solid;
				cursor: pointer;
			}
		}
	}
	.summary-section {
		margin-bottom: 20px;
		.page-title {
			margin-bottom: 4px;
			.merchant-badge {
				color: #000;
				font-size: 12px;
				line-height: 16px;
				font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
				margin-bottom: 8px;
			}
			.product-title {
				line-height: 20px;
				color: #000;
				text-transform: uppercase;
				font-family: InterstateWGL-Black, Helvetica, Arial, sans-serif;
				font-size: 16px;
				letter-spacing: 0.02em;
				font-weight: 900;
			}
		}
		.swatch-section {
			margin-top: 16px;
			margin-bottom: 24px;
			.swatch-name {
				font-size: 12px;
				line-height: 16px;
				font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
				color: #6e6e6e;
			}
			.swatch {
				height: 30px;
				width: 30px;
				display: inline-block;
				padding: 2px;
				margin-right: 18px;
				margin-top: 12px;
				// &:first-of-type {
				//   border-radius: 50%;
				//   border: 1px solid #403e3f;
				// }
				img {
					width: 24px;
					height: 24px;
					top: calc(50% - 12px);
					left: calc(50% - 12px);
					border-radius: 50%;
				}
			}
		}
		.size-section {
			.size-title-header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 0 0 20px 0;
				.size-header {
					color: #000;
					font-size: 14px;
					line-height: 20px;
					font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
					font-weight: 700;
				}
			}
			.variant-section {
				.waise-section,
				.length-section {
					margin-bottom: 16px;
				}
				.variant-title {
					font-size: 12px;
					line-height: 16px;
					font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
					color: #6e6e6e;
				}
				.size-list {
					display: flex;
					flex: 0 0 auto;
					flex-flow: row wrap;
					align-items: stretch;
					margin-left: 0;
					margin-bottom: 0;
					padding-left: 0;
					padding-top: 12px;
					margin-right: 16px;
					.size-item {
						list-style: none;
						margin-right: 16px;
						padding: 0;
						button {
							background-color: #fff;
							border: 1px solid #fff;
							border-radius: 2px;
							display: flex;
							align-items: center;
							justify-content: center;
							height: 48px;
							list-style-type: none;
							outline: none;
							overflow: hidden;
							position: relative;
							text-align: center;
							text-decoration: none;
							width: 48px;
							span {
								font-size: 12px;
								line-height: 16px;
								font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
								color: #000;
								display: block;
								text-decoration: none;
							}
						}
					}
				}
			}
		}
		.price-section {
			.price {
				color: #000;
				font-size: 20px;
				line-height: 28px;
				font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
				font-weight: 700;
			}
			.after-pay {
				margin-top: 4px;
				font-family: Helvetica-Now-Text-Regular, arial, sans-serif;
				font-size: 12px;
				color: rgb(117, 117, 117);
				font-weight: 500;
				line-height: 0.86;
			}
		}
		.qty-section {
			margin-top: 34px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.qty-title {
				color: #000;
				font-size: 14px;
				line-height: 20px;
				font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
				font-weight: 700;
			}
			.qty-counter {
				.qty-button {
					border: 0;
					background-color: transparent;
					padding: 0;
					margin: 0;
					margin-right: 32px;
				}
				.selected-qty {
					font-size: 14px;
					line-height: 20px;
					font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
					font-weight: 700;
					color: #000;
					margin-right: 32px;
				}
			}
		}
		.ship-options-section {
			margin-top: 20px;
			.ship-option-text {
				margin-left: 16px;
				padding: 4px 0 24px;
				.ship-title {
					color: #000;
					font-size: 14px;
					line-height: 20px;
					font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
				}
				.ship-sub-title {
					margin-top: 5px;
					font-size: 12px;
					line-height: 16px;
					font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
					color: #6e6e6e;
				}
				.select-store {
					font-size: 14px;
					line-height: 20px;
					font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
					font-weight: 700;
					text-decoration: underline;
					cursor: pointer;
					color: #b2b2b2;
					margin-top: 10px;
				}
			}
		}
		.cta-section {
			.full-btn {
				button {
					cursor: pointer;
					display: inline-block;
					text-align: center;
					text-decoration: none;
					user-select: none;
					vertical-align: middle;
					font-size: 16px;
					line-height: 24px;
					font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
					font-weight: 700;
					text-transform: none;
					background-color: #070b21;
					color: #fff;
					width: 100%;
					padding: 12px 16px;
					transition: background-color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
					margin: 16px 0 20px;
					margin-bottom: 20px;
					height: 100%;
					&.ghost-btn {
						background: #fff;
						border: 1px solid #000;
						color: #000;
					}
				}
			}
		}
	}
	.box-separator {
		width: 100%;
		border: 0;
		margin: 24px 0;
		border-top: 1px solid #f3f3f3;
	}

	.product-spec-overview {
		color: #000;
		font-size: 20px;
		line-height: 28px;
		font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
		.pdp-tabs-title {
			color: #000;
			font-size: 16px;
			line-height: 24px;
			font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
			font-weight: 700;
		}
		.contentContainer {
			font-size: 14px;
			line-height: 20px;
			font-family: Helvetica-Now-Text-Regular, Helvetica, Arial, sans-serif;
			color: #6e6e6e;
		}
	}

	.pdp-specification-section {
		padding: 64px 0;
		border-top: 1px solid #e9e9e9;
	}

	.stick-footer {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: #fff;
		color: #fff;
		font-size: 16px;
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 25%);
		display: block;
		z-index: 300;
		padding: 8px 20px;
		.footer-btn-container {
			box-sizing: border-box;
			display: flex;
			flex: 0 1 auto;
			flex-direction: row;
			flex-wrap: wrap;
			position: relative;
			.footer-btn {
				&.fav-btn {
					width: 20%;
					padding: 0px;
					padding-right: 4px;
					margin-bottom: 0;
					padding-left: 0;
					button {
						background: #f3f3f3;
						border: 0;
						color: #b2b2b2;
						height: 100%;
						appearance: none;
						cursor: pointer;
						display: inline-block;
						text-align: center;
						text-decoration: none;
						user-select: none;
						vertical-align: middle;
						font-size: 16px;
						line-height: 24px;
						font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
						font-weight: 700;
						border-radius: 2px;
						text-transform: none;
						width: 100%;
						padding: 12px 16px;
						transition: background-color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
					}
				}
				&.bag-btn {
					flex-grow: 1;
					button {
						background: #f3f3f3;
						border: 0;
						margin-top: 0;
						color: #b2b2b2;
						padding: 4px;
						appearance: none;
						cursor: pointer;
						display: inline-block;
						text-align: center;
						text-decoration: none;
						user-select: none;
						vertical-align: middle;
						font-size: 16px;
						line-height: 24px;
						font-family: Helvetica-Now-Text-Bold, Helvetica, Arial, sans-serif;
						font-weight: 700;
						border-radius: 2px;
						text-transform: none;
						width: 100%;
						transition: background-color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
						margin-bottom: 20px;
						height: 100%;
					}
				}
			}
		}
	}
}

//Mobile view
@media (max-width: 767px) {
	.aistetic-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			height: 24px;
		}
	}

	.search-nav-row {
		padding: 10px 0px;
	}

	.pl-section {
		margin-top: 100px;
	}

	.type-container {
		width: 40vw;
		.type-title {
			font-size: 14px;
		}
	}

	.pd-section {
		margin: 10px;
		margin-top: 225px;
		.gallery-section {
			padding-left: 0px;
			flex-direction: column-reverse;
			padding-bottom: 20px;
			.thumbnail-list {
				// width: auto;
				flex-direction: row;
				position: relative;
				margin: 2vw 0 0 2vw;
				overflow-x: auto;
				.thumbnail {
					// margin-bottom: 2vw;
					padding: 0;
					margin-right: 2vw;
					width: 26vw;
					position: relative;
					img {
						// display: block;
						// width: 100%;
						max-width: 100%;
						top: 0;
						left: 0;
						width: 100%;
						object-fit: cover;
						object-position: 50% 50%;
					}
				}
			}

			.hero-image {
				width: 100vw;
				margin-left: -1.4rem;
				position: relative;
				img {
					max-width: 100%;
				}
				.floating-child {
					position: absolute;
					top: 0;
					left: 0;
					float: left;
					width: auto;
					text-align: center;
					background-color: #000;
					font-size: 12px;
					padding: 10px;
					border-radius: 5px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					display: flex;
					gap: 10px;
					cursor: pointer;
					.floating-child-new {
						background-color: #ffff00;
						padding: 1px 8px 1px 8px;
						border-radius: 4px;
						font-weight: 700;
					}
					.floating-child-room {
						color: white;
						font-weight: 700;
					}
				}
			}
		}
		.summary-section {
			.page-title-container {
				position: absolute;
				top: 120px;
			}
		}
	}
	.widget-trigger {
		width: 90%;
	}
}

//Desktop view
@media (min-width: 768px) {
	.aistetic-logo {
		img {
			height: 32px;
		}
	}
	.search-nav-row {
		padding: 20px 0px;
	}
	.type-container {
		width: 16vw;
		.type-title {
			font-size: 16px;
		}
	}
	.pd-section {
		margin: 10px;
		margin-top: 125px;
		.gallery-section {
			flex-direction: row;
			padding-bottom: 100px;
			.thumbnail-list {
				width: 20%;
				flex-direction: column;
				.thumbnail {
					margin-bottom: 2vw;
					img {
						display: block;
						width: 100%;
					}
				}
			}
			.hero-image {
				position: relative;
				margin-top: 10px;
				.floating-child {
					position: absolute;
					top: 12px;
					left: 0;
					float: left;
					width: auto;
					text-align: center;
					background-color: #000;
					font-size: 12px;
					padding: 10px;
					border-radius: 5px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					display: flex;
					gap: 10px;
					cursor: pointer;
					.floating-child-new {
						background-color: #ffff00;
						padding: 1px 8px 1px 8px;
						border-radius: 4px;
						font-weight: 700;
					}
					.floating-child-room {
						color: white;
						font-weight: 700;
					}
				}
				img {
					max-width: 100%;
				}
			}
		}
	}
	.widget-trigger {
		width: 45%;
	}
}
